import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ValoresService } from 'app/services/inicio/valores.service';

@Component({
  selector: 'app-valores-corporativos',
  templateUrl: './valores-corporativos.component.html',
  styleUrls: ['./valores-corporativos.component.scss']
})
export class ValoresCorporativosComponent implements OnInit {

  displayedColumns: string[] = ['idValor', 'nombre', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private serviciovalores: ValoresService) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.serviciovalores.getValores().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
