import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ProductoMarcaService } from 'app/services/productos/marcas.service';

@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.scss']
})
export class MarcasComponent implements OnInit {


  displayedColumns: string[] = ['orden','marca', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public datos_nosotros: any = [];

  constructor(private servicemarcas: ProductoMarcaService, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicemarcas.getCMarcas().subscribe(response =>{
      this.datos_nosotros = response;
      this.dataSource = new MatTableDataSource(this.datos_nosotros)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarDato = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicemarcas.eliminarMarca(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.datos_nosotros, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.datos_nosotros);
    this.dataSource.sort = this.sort;
    this.datos_nosotros.reverse()
    this.datos_nosotros.forEach(marca => {
      marca.orden = i;

      if (marca.estado == 'ACTIVO') {
        marca.estado = 1;
      } else {
        marca.estado = 0;
      }

      this.servicemarcas.actualizarMarca(marca.idMarca, marca).subscribe(response => {
      });


      if (marca.estado == 1) {
        marca.estado = 'ACTIVO';
      } else {
        marca.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.datos_nosotros.reverse()
  }

}
