import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {

  constructor(private tokenservice: LoginService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: any;
    let req = request;
    //AL HEADER DE TODAS LAS CONSULTAS LE AÑADIMOS LA VARIABLE "token", QUE NOS VA A PERMITIR OBTENER INFORMACIÓN DEL SERVIDOR
    this.tokenservice.getToken().subscribe((response: any) => {
      if(response){
        token = response.token ? response.token: '';
      }
      if(token){
        req = request.clone({
          //SE MODIFICA EL ENCABEZADO PARA AÑADIR EL TOKEN
          setHeaders:{
            Authorization: `${token}`
          }
        })
      }
    })
    return next.handle(req);
  }
}
