import { Routes } from '@angular/router';
import { AvisoPrivacidadDetalleComponent } from 'app/aviso-privacidad/aviso-privacidad-detalle/aviso-privacidad-detalle.component';
import { AvisoPrivacidadComponent } from 'app/aviso-privacidad/aviso-privacidad.component';
import { CertificadoDetalleComponent } from 'app/certificacion/certificados/certificado-detalle/certificado-detalle.component';
import { CertificadosComponent } from 'app/certificacion/certificados/certificados.component';
import { GruposPermisosDetalleComponent } from 'app/configuracion/grupos-permisos/grupos-permisos-detalle/grupos-permisos-detalle.component';
import { GruposPermisosComponent } from 'app/configuracion/grupos-permisos/grupos-permisos.component';
import { UsuariosDetalleComponent } from 'app/configuracion/usuarios/usuarios-detalle/usuarios-detalle.component';
import { UsuariosComponent } from 'app/configuracion/usuarios/usuarios.component';
import { ContactoDetalleComponent } from 'app/contacto/contacto/contacto-detalle/contacto-detalle.component';
import { ContactoComponent } from 'app/contacto/contacto/contacto.component';
import { RedesSocialesComponent } from 'app/contacto/redes-sociales/redes-sociales.component';
import { FloracionImagenComponent } from 'app/floraciones/floracion-imagen/floracion-imagen.component';
import { FloracionDetalleComponent } from 'app/floraciones/floracion/floracion-detalle/floracion-detalle.component';
import { FloracionComponent } from 'app/floraciones/floracion/floracion.component';
import { ClasificacionGaleriaDetalleComponent } from 'app/galeria/clasificacion/clasificacion-detalle/clasificacion-detalle.component';
import { ClasificacionGaleriaComponent } from 'app/galeria/clasificacion/clasificacion.component';
import { GaleriaDetalleComponent } from 'app/galeria/galeria/galeria-detalle/galeria-detalle.component';
import { GaleriaComponent } from 'app/galeria/galeria/galeria.component';
import { BienvenidaComponent } from 'app/inicio/bienvenida/bienvenida.component';
import { NosotrosDetalleComponent } from 'app/inicio/nosotros/nosotros-detalle/nosotros-detalle.component';
import { NosotrosComponent } from 'app/inicio/nosotros/nosotros.component';
import { ValoresCorporativosDetalleComponent } from 'app/inicio/valores-corporativos/valores-corporativos-detalle/valores-corporativos-detalle.component';
import { ValoresCorporativosComponent } from 'app/inicio/valores-corporativos/valores-corporativos.component';
//Portada
import { PortadaComponent } from 'app/inicio/portada/portada.component';
import { ClasificacionDetalleComponent } from 'app/productos/clasificacion/clasificacion-detalle/clasificacion-detalle.component';
import { ClasificacionComponent } from 'app/productos/clasificacion/clasificacion.component';
import { ProductoDetalleComponent } from 'app/productos/productos/producto-detalle/producto-detalle.component';
import { ProductosComponent } from 'app/productos/productos/productos.component';
import { ProveedoresImagenesComponent } from 'app/proveedores/proveedores-imagenes/proveedores-imagenes.component';
import { ProveedorDetalleComponent } from 'app/proveedores/proveedores/proveedor-detalle/proveedor-detalle.component';
import { ProveedoresComponent } from 'app/proveedores/proveedores/proveedores.component';
import { SeccionesImagenesComponent } from 'app/secciones/secciones-imagenes/secciones-imagenes.component';

//Componentes
import { CanDeactivateGuard } from 'app/services/componentes/can-deactive-guard.service';
import { AvisosContenidoComponent } from 'app/vinculacion/avisos/avisos-contenido/avisos-contenido.component';
import { AvisoDetalleComponent } from 'app/vinculacion/avisos/avisos/aviso-detalle/aviso-detalle.component';
import { AvisosComponent } from 'app/vinculacion/avisos/avisos/avisos.component';
import { EmpresaContenidoComponent } from 'app/vinculacion/empresa/empresa-contenido/empresa-contenido.component';
import { EmpresaDetalleComponent } from 'app/vinculacion/empresa/empresa/empresa-detalle/empresa-detalle.component';
import { EmpresaComponent } from 'app/vinculacion/empresa/empresa/empresa.component';
import { SociedadContenidoComponent } from 'app/vinculacion/sociedad/sociedad-contenido/sociedad-contenido.component';
import { SociedadDetalleComponent } from 'app/vinculacion/sociedad/sociedad/sociedad-detalle/sociedad-detalle.component';
import { SociedadComponent } from 'app/vinculacion/sociedad/sociedad/sociedad.component';

import { HomeComponent } from '../../home/home.component';
import { MarcasComponent } from 'app/productos/marcas/marcas.component';
import { MarcaDetalleComponent } from 'app/productos/marcas/marca-detalle/marca-detalle.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', component: HomeComponent },
    //INICIO
    { path: 'portada', component: PortadaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'bienvenida', component: BienvenidaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'nosotros', component: NosotrosComponent },
    { path: 'nosotros-detalle', component: NosotrosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'nosotros-detalle/:idSeccion', component: NosotrosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'valores', component: ValoresCorporativosComponent },
    { path: 'valores-detalle', component: ValoresCorporativosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'valores-detalle/:idValor', component: ValoresCorporativosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //CERTIFICADO
    { path: 'certificados', component: CertificadosComponent },
    { path: 'certificado-detalle', component: CertificadoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'certificado-detalle/:idCertificacion', component: CertificadoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //FLORACIONES
    { path: 'floracion-imagen', component: FloracionImagenComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'floraciones', component: FloracionComponent },
    { path: 'floracion-detalle', component: FloracionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'floracion-detalle/:idPeriodo', component: FloracionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //PRODUCTOS
    { path: 'clasificaciones', component: ClasificacionComponent },
    { path: 'clasificacion-detalle', component: ClasificacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'clasificacion-detalle/:idClasificacion', component: ClasificacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'productos', component: ProductosComponent },
    { path: 'producto-detalle', component: ProductoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'producto-detalle/:idProducto', component: ProductoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'marcas', component: MarcasComponent },
    { path: 'marca-detalle', component: MarcaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'marca-detalle/:idMarca', component: MarcaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    
    //PROVEEDORES
    { path: 'proveedores-imagen', component: ProveedoresImagenesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'proveedores', component: ProveedoresComponent },
    { path: 'proveedor-detalle', component: ProveedorDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'proveedor-detalle/:idProveedor', component: ProveedorDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //VINCULACION
    { path: 'avisos-contenido', component: AvisosContenidoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'avisos', component: AvisosComponent },
    { path: 'aviso-detalle', component: AvisoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'aviso-detalle/:idAviso', component: AvisoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'empresa-contenido', component: EmpresaContenidoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'empresa', component: EmpresaComponent },
    { path: 'empresa-detalle', component: EmpresaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'empresa-detalle/:idEmpresa', component: EmpresaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'sociedad-contenido', component: SociedadContenidoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'sociedad', component: SociedadComponent },
    { path: 'sociedad-detalle', component: SociedadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'sociedad-detalle/:idSociedad', component: SociedadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //GALERIA
    { path: 'clasificaciones-galeria', component: ClasificacionGaleriaComponent },
    { path: 'clasificacion-galeria-detalle', component: ClasificacionGaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'clasificacion-galeria-detalle/:idClasificacion', component: ClasificacionGaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'galeria', component: GaleriaComponent },
    { path: 'galeria-detalle', component: GaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'galeria-detalle/:idGaleria', component: GaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },

    //CONTACTO
    { path: 'contactos', component: ContactoComponent },
    { path: 'contacto-detalle', component: ContactoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'contacto-detalle/:idContacto', component: ContactoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'redes-sociales', component: RedesSocialesComponent, canDeactivate: [CanDeactivateGuard] },

    //SECCIONES IMAGENES
    { path: 'seccion-imagen', component: SeccionesImagenesComponent, canDeactivate: [CanDeactivateGuard] },

    //AVISOS DE PRIVACIDAD
    { path: 'avisos-privacidad', component: AvisoPrivacidadComponent },
    { path: 'aviso-privacidad-detalle', component: AvisoPrivacidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'aviso-privacidad-detalle/:idAviso', component: AvisoPrivacidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },

    //CONFIGURACION
    { path: 'grupos-permisos', component: GruposPermisosComponent },
    { path: 'grupos-permisos-detalle', component: GruposPermisosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'grupos-permisos-detalle/:idGrupo', component: GruposPermisosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'usuarios', component: UsuariosComponent },
    { path: 'usuarios-detalle', component: UsuariosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'usuarios-detalle/:idUsuario', component: UsuariosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
];
