import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { ClasificacionProductosService } from 'app/services/productos/clasificacion.service';
import { ProductoMarcaService } from 'app/services/productos/marcas.service';
import { ProductosService } from 'app/services/productos/producto.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.scss']
})
export class ProductoDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DEL PRODUCTO QUE EL USUARIO SELECCIONO 
  idProducto: string = this.obtenerId().idProducto;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_producto: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ProductoForm = new FormGroup({
    idMarca: new FormControl(1),
    idClasificacion: new FormControl(0, Validators.required),
    nombre: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    rutaImagen: new FormControl('', Validators.required),
    orden: new FormControl(0),
    estado: new FormControl(1),
  });

  array: any = [];
  clasificaciones: any = []
  marcas: any = []

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioproducto: ProductosService,
    private serviciodocumento: DocumentService,
    private servicioclasificacion: ClasificacionProductosService,
    private servicioMarcas: ProductoMarcaService,  
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.filter(params => params.idMarca).subscribe(params =>{ 
     if(params) { 
      this.ProductoForm.get("idMarca").setValue(parseInt(params.idMarca));
      this.ProductoForm.get("idClasificacion").setValue(parseInt(params.idClasificacion)); 
     }
    })
    this.obtenerClasificaciones()
    this.obtenerMarcas()
    if (this.idProducto) {
      this.servicioproducto.getProducto(this.idProducto).subscribe(response => {
        console.log(response)
        this.ProductoForm.patchValue(response[0])
        this.imagen_producto = response[0].rutaImagen;

      });
    }
  }

  obtenerClasificaciones = () =>{
    this.servicioclasificacion.getClasificacionProductos().subscribe(response =>{
      this.clasificaciones = response;
    })
  }

  obtenerMarcas = () =>{
    this.servicioMarcas.getCMarcas().subscribe(response =>{
      this.marcas = response;
    })
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ProductoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  // funcion para enviar parametros de consulta a la url
  queryParamsBackFunction = () =>{  
    // enviamos como queryparams los id de los campos marcas y clasificiacion
    this.router.navigate(['/productos'], {queryParams: {'idMarca': this.ProductoForm.get('idMarca').value, 'idClasificacion': this.ProductoForm.get('idClasificacion').value}});
  }
  guardarDatos = () => {
    let datos: any = [];
    let imagen = this.ProductoForm.get('rutaImagen').value;

    if (this.ProductoForm.get('rutaImagen').value) {
      this.ProductoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioproducto.getProductos(this.ProductoForm.get('idClasificacion').value, this.ProductoForm.get('idMarca').value).subscribe(response => {
      datos = response;
      if (response) {
        this.ProductoForm.controls['orden'].setValue(datos.length + 1)
        this.servicioproducto.crearProducto(this.ProductoForm.value).subscribe(response => {

          if (this.ProductoForm.get('rutaImagen').value) {
            this.guardarImagenPHP(imagen)
          }

          this.ProductoForm.markAsPristine()
          this.ProductoForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          // this.router.navigateByUrl('/productos') 
          this.queryParamsBackFunction(); 
        });
      }
    })
  }

 




  actualizarDatos = () => {
    let imagen = this.ProductoForm.get('rutaImagen').value;

    if (this.ProductoForm.get('rutaImagen').value) {
      this.ProductoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioproducto.actualizarProducto(this.idProducto, this.ProductoForm.value).subscribe(response => {
      if (this.ProductoForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }
      this.ProductoForm.markAsPristine()
      this.ProductoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/productos')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    this.ProductoForm.controls['rutaImagen'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.ProductoForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });

  }

  eliminarImagen = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioproducto.actualizarEstadoImagen(this.idProducto, this.ProductoForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen_producto).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }

}
