import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { BienvenidaService } from 'app/services/inicio/bienvenida.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {


  BienvenidaForm = new FormGroup({
    contenido: new FormControl('', [Validators.required])
  });

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null),
  })
  
  IMAGEN_HOST = environment.API_IMAGEN;
  imagenes: any = [];
  constructor(private serviciobienvenida: BienvenidaService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    this.obtenerDatos()
    this.obtenerImagenes()
  }

  obtenerImagenes = () => {
    this.serviciobienvenida.getImagenes().subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.BienvenidaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  eliminarImagen = (id, name) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.serviciobienvenida.eliminarImagen(id).subscribe(response=>{
       this.serviciodocumento.eliminarImagen(name).subscribe(response =>{

       });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
  
    })
  }

  obtenerDatos = () => {
    this.serviciobienvenida.getBienvenida(1).subscribe(response => {
      // this.ckeditorContent = response[0].BienvenidaForm;
      this.BienvenidaForm.patchValue(response[0]);
      this.BienvenidaForm.markAsPristine()
      this.BienvenidaForm.markAllAsTouched()
    })
  }


  guardarDatos = () => {

    let imagen = this.ImagenesForm.get('rutaImagen').value;

    if(this.ImagenesForm.get('rutaImagen').value){
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.serviciobienvenida.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
        })
      });
    }

    this.serviciobienvenida.actualizarBienvenida(1, this.BienvenidaForm.value).subscribe(response => {
      this.BienvenidaForm.markAsPristine()
      this.BienvenidaForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
      this.ngOnInit()
    })

  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;

    moveItemInArray(this.imagenes, event.previousIndex, event.currentIndex);

    this.imagenes.reverse()
    this.imagenes.forEach(dato => {
      dato.orden = i;

      this.serviciobienvenida.actualizarDatoNosotrosImagenes(dato.idSlider, dato).subscribe(response => {
      });


      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.imagenes.reverse()

  }

}
