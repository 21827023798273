import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ClasificacionService } from 'app/services/galeria/clasificaciones.service';
import { GaleriaService } from 'app/services/galeria/galeria.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {


  displayedColumns: string[] = ['idGaleria', 'titulo','ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  idClasificacion: any = 1;
  clasificaciones: any = [];
  galerias: any = [];

  constructor(private serviciogaleria: GaleriaService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioclasificacion: ClasificacionService) { }

  ngOnInit(): void {
    this.obtenerClasificaciones()
    this.obtenerGaleria()
  }

  obtenerGaleria = () => {
    this.serviciogaleria.getGaleriaPorClasificacion(this.idClasificacion).subscribe(response => {
      this.galerias = response;
      this.dataSource = new MatTableDataSource(this.galerias)
      this.dataSource.sort = this.sort
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarGaleria = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciogaleria.eliminarGaleria(id).subscribe(cresponse => {
          this.obtenerGaleria()
        });
      }

    })
  }

  obtenerClasificaciones = () => {
    this.servicioclasificacion.getClasificaciones().subscribe(response =>{
      this.clasificaciones = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  cambios = (event) => {
    this.obtenerGaleria()
  }

}
