import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguiMapModule } from '@ngui/map';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { HomeComponent } from '../../home/home.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { CKEditorModule } from 'ng2-ckeditor';

//COMPONENTES PERSONALIZADOS
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';

//PORTADA
import { PortadaComponent } from 'app/inicio/portada/portada.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { BienvenidaComponent } from 'app/inicio/bienvenida/bienvenida.component';
import { NosotrosComponent } from 'app/inicio/nosotros/nosotros.component';
import { NosotrosDetalleComponent } from 'app/inicio/nosotros/nosotros-detalle/nosotros-detalle.component';
import { ValoresCorporativosComponent } from 'app/inicio/valores-corporativos/valores-corporativos.component';
import { ValoresCorporativosDetalleComponent } from 'app/inicio/valores-corporativos/valores-corporativos-detalle/valores-corporativos-detalle.component';

//CERTIFICADOS
import { CertificadosComponent } from 'app/certificacion/certificados/certificados.component';
import { CertificadoDetalleComponent } from 'app/certificacion/certificados/certificado-detalle/certificado-detalle.component';
import { FloracionImagenComponent } from 'app/floraciones/floracion-imagen/floracion-imagen.component';
import { FloracionComponent } from 'app/floraciones/floracion/floracion.component';
import { FloracionDetalleComponent } from 'app/floraciones/floracion/floracion-detalle/floracion-detalle.component';
import { ClasificacionComponent } from 'app/productos/clasificacion/clasificacion.component';
import { ClasificacionDetalleComponent } from 'app/productos/clasificacion/clasificacion-detalle/clasificacion-detalle.component';
import { ProductosComponent } from 'app/productos/productos/productos.component';
import { ProductoDetalleComponent } from 'app/productos/productos/producto-detalle/producto-detalle.component';
import { ProveedoresImagenesComponent } from 'app/proveedores/proveedores-imagenes/proveedores-imagenes.component';
import { ProveedoresComponent } from 'app/proveedores/proveedores/proveedores.component';
import { ProveedorDetalleComponent } from 'app/proveedores/proveedores/proveedor-detalle/proveedor-detalle.component';
import { AvisosContenidoComponent } from 'app/vinculacion/avisos/avisos-contenido/avisos-contenido.component';
import { AvisosComponent } from 'app/vinculacion/avisos/avisos/avisos.component';
import { AvisoDetalleComponent } from 'app/vinculacion/avisos/avisos/aviso-detalle/aviso-detalle.component';
import { EmpresaContenidoComponent } from 'app/vinculacion/empresa/empresa-contenido/empresa-contenido.component';
import { EmpresaComponent } from 'app/vinculacion/empresa/empresa/empresa.component';
import { EmpresaDetalleComponent } from 'app/vinculacion/empresa/empresa/empresa-detalle/empresa-detalle.component';
import { SociedadContenidoComponent } from 'app/vinculacion/sociedad/sociedad-contenido/sociedad-contenido.component';
import { SociedadComponent } from 'app/vinculacion/sociedad/sociedad/sociedad.component';
import { SociedadDetalleComponent } from 'app/vinculacion/sociedad/sociedad/sociedad-detalle/sociedad-detalle.component';
import { ClasificacionGaleriaComponent } from 'app/galeria/clasificacion/clasificacion.component';
import { ClasificacionGaleriaDetalleComponent } from 'app/galeria/clasificacion/clasificacion-detalle/clasificacion-detalle.component';
import { GaleriaComponent } from 'app/galeria/galeria/galeria.component';
import { ContactoComponent } from 'app/contacto/contacto/contacto.component';
import { ContactoDetalleComponent } from 'app/contacto/contacto/contacto-detalle/contacto-detalle.component';
import { RedesSocialesComponent } from 'app/contacto/redes-sociales/redes-sociales.component';
import { GaleriaDetalleComponent } from 'app/galeria/galeria/galeria-detalle/galeria-detalle.component';
import { SeccionesImagenesComponent } from 'app/secciones/secciones-imagenes/secciones-imagenes.component';
import { AvisoPrivacidadComponent } from 'app/aviso-privacidad/aviso-privacidad.component';
import { AvisoPrivacidadDetalleComponent } from 'app/aviso-privacidad/aviso-privacidad-detalle/aviso-privacidad-detalle.component';
import { GruposPermisosComponent } from 'app/configuracion/grupos-permisos/grupos-permisos.component';
import { GruposPermisosDetalleComponent } from 'app/configuracion/grupos-permisos/grupos-permisos-detalle/grupos-permisos-detalle.component';
import { UsuariosComponent } from 'app/configuracion/usuarios/usuarios.component';
import { UsuariosDetalleComponent } from 'app/configuracion/usuarios/usuarios-detalle/usuarios-detalle.component';
import { MarcasComponent } from 'app/productos/marcas/marcas.component';
import { MarcaDetalleComponent } from 'app/productos/marcas/marca-detalle/marca-detalle.component';


@NgModule({
  imports: [
    ReactiveFormsModule,
    MaterialFileInputModule,
    CKEditorModule,
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=YOUR_KEY_HERE' }),
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatExpansionModule,
    MatSliderModule,
  ],
  declarations: [
    HomeComponent,
    //PORTADA
    PortadaComponent,
    BienvenidaComponent,
    NosotrosComponent,
    NosotrosDetalleComponent,
    ValoresCorporativosComponent,
    ValoresCorporativosDetalleComponent,

    //CERTIFICADOS
    CertificadosComponent,
    CertificadoDetalleComponent,

    //FLORACIONES
    FloracionImagenComponent,
    FloracionComponent,
    FloracionDetalleComponent,

    //PRODUCTOS
    ClasificacionComponent,
    ClasificacionDetalleComponent,
    ProductosComponent,
    ProductoDetalleComponent,
    MarcasComponent,
    MarcaDetalleComponent,

    //PROVEEDORES
    ProveedoresImagenesComponent,
    ProveedoresComponent,
    ProveedorDetalleComponent,

    //VINCULACION
    AvisosContenidoComponent,
    AvisosComponent,
    AvisoDetalleComponent,
    EmpresaContenidoComponent,
    EmpresaComponent,
    EmpresaDetalleComponent,
    SociedadContenidoComponent,
    SociedadComponent,
    SociedadDetalleComponent,

    //GALERIA
    ClasificacionGaleriaComponent,
    ClasificacionGaleriaDetalleComponent,
    GaleriaComponent,
    GaleriaDetalleComponent,

    //CONTACTO
    ContactoComponent,
    ContactoDetalleComponent,
    RedesSocialesComponent,

    //SECCIONES IMAGENES
    SeccionesImagenesComponent,

    //AVISOS DE PRIVACIDAD
    AvisoPrivacidadComponent,
    AvisoPrivacidadDetalleComponent,

    //COMPONENTES CREADOS
    MatDialogComponent,
    CuadroDialogoComponent,

    //CONFIGURACIÓN
    GruposPermisosComponent,
    GruposPermisosDetalleComponent,
    UsuariosComponent,
    UsuariosDetalleComponent,

  ]
})

export class AdminLayoutModule { }
