import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EmpresaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getEmpresaContenido(id:number){
    return this.http.get(`${this.API_URI}/empresa-contenido/${id}`);
  }

  actualizarEmpresaContenido(id: number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/empresa-contenido/${id}`, sede);
  }


  //MODULO DE AVISOS EMPRESA

  getEmpresasAvisos(){
    return this.http.get(`${this.API_URI}/empresa`)
  }

  getEmpresa(id:string){
    return this.http.get(`${this.API_URI}/empresa/${id}`);
  }

  actualizarEmpresa(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/empresa/${id}`, datos);
  }
  
  crearEmpresa(datos: any) {
    return this.http.post(`${this.API_URI}/empresa`, datos);
  }

  eliminarEmpresa(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/empresa/${id}`);
  }

    //IMAGENES POR EMPRESA

    getImagenes(id:string){
      return this.http.get(`${this.API_URI}/empresa/imagenes/${id}`);
    }
  
    crearImagenes(datos: any) {
      return this.http.post(`${this.API_URI}/empresa/imagenes/porEmpresa`, datos);
    }
  
    eliminarImagen(id: string): Observable<any> {
      return this.http.delete(`${this.API_URI}/empresa/imagenes/${id}`);
    }
  
    actualizarEmpresaImagen(id: string | number, datos: any): Observable<any> {
      return this.http.put(`${this.API_URI}/empresa/imagenes/${id}`, datos);
    }

}
