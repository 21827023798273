import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { AvisosService } from 'app/services/vinculacion/avisos/avisos.service';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit {

  displayedColumns: string[] = ['orden','rutaImagen', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public datos_avisos: any = [];

  constructor(private serviceavisos: AvisosService, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.serviceavisos.getAvisos().subscribe(response =>{
      this.datos_avisos = response;
      this.dataSource = new MatTableDataSource(this.datos_avisos)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarDato = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceavisos.eliminarAviso(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.datos_avisos, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.datos_avisos);
    this.dataSource.sort = this.sort;
    this.datos_avisos.reverse()
    this.datos_avisos.forEach(aviso => {
      aviso.orden = i;

      if (aviso.estado == 'ACTIVO') {
        aviso.estado = 1;
      } else {
        aviso.estado = 0;
      }

      this.serviceavisos.actualizarAviso(aviso.idAviso, aviso).subscribe(response => {
      });


      if (aviso.estado == 1) {
        aviso.estado = 'ACTIVO';
      } else {
        aviso.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.datos_avisos.reverse()
    // this.obtenerDatos()
  }
}
