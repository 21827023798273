import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AvisoPrivacidadService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getAvisosPrivacidad(){
    return this.http.get(`${this.API_URI}/aviso-privacidad`)
  }

  getAvisoPrivacidad(id:string){
    return this.http.get(`${this.API_URI}/aviso-privacidad/${id}`);
  }

  actualizarAvisoPrivacidad(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/aviso-privacidad/${id}`, datos);
  }
  
  crearAvisoPrivacidad(datos: any) {
    return this.http.post(`${this.API_URI}/aviso-privacidad`, datos);
  }

  eliminarAvisoPrivacidad(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/aviso-privacidad/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/aviso-privacidad/estadoDocumento/${id}`, datos);
  }


}
