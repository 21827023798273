import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { ClasificacionService } from 'app/services/galeria/clasificaciones.service';
import { GaleriaService } from 'app/services/galeria/galeria.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-galeria-detalle',
  templateUrl: './galeria-detalle.component.html',
  styleUrls: ['./galeria-detalle.component.scss']
})
export class GaleriaDetalleComponent implements OnInit {
  favoriteSeason: string;
  seasons: string[] = ['Imgen', 'Video'];

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DEL PRODUCTO QUE EL USUARIO SELECCIONO 
  idGaleria: string = this.obtenerId().idGaleria;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_galeria: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  GaleriaForm = new FormGroup({
    idClasificacion: new FormControl(0, Validators.required),
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    rutaImagen: new FormControl(''),
    // radioButton: new FormControl(), 
  }); 

  radioForm = new FormGroup({ 
    radioButton: new FormControl("true"), 
  }); 

  array: any = [];
  clasificaciones: any = []

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciogaleria: GaleriaService,
    private serviciodocumento: DocumentService,
    private servicioclasificacion: ClasificacionService
  ) { } 
  // getter para obtener el valor del radio button
  getRadioButton = () =>  this.radioForm.get("radioButton") as FormControl; 
  ngOnInit(): void { 
    this.obtenerClasificaciones()
    if (this.idGaleria) {
      this.serviciogaleria.getGaleria(this.idGaleria).subscribe(response => {
        this.GaleriaForm.patchValue(response[0])
        this.imagen_galeria = response[0].rutaImagen;

      });
    }
  }

  obtenerClasificaciones = () => {
    this.servicioclasificacion.getClasificaciones().subscribe(response => {
      this.clasificaciones = response;
    })
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.GaleriaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let imagen = this.GaleriaForm.get('rutaImagen').value;
    if (this.GaleriaForm.get('rutaImagen').value) {
      this.GaleriaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.serviciogaleria.crearGaleria(this.GaleriaForm.value).subscribe(response => {

      if (this.GaleriaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      this.GaleriaForm.markAsPristine()
      this.GaleriaForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/galeria')
    });



  }

  actualizarDatos = () => {
    let imagen = this.GaleriaForm.get('rutaImagen').value;

    if (this.GaleriaForm.get('rutaImagen').value) {
      this.GaleriaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.serviciogaleria.actualizarGaleria(this.idGaleria, this.GaleriaForm.value).subscribe(response => {
      if (this.GaleriaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }
      this.GaleriaForm.markAsPristine()
      this.GaleriaForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/galeria')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    this.GaleriaForm.controls['rutaImagen'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.GaleriaForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });

  }

  eliminarImagen = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciogaleria.actualizarEstadoImagen(this.idGaleria, this.GaleriaForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen_galeria).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }
}
