import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProveedoresService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  //SECCION IMÁGENES
  getImagenes(){
    return this.http.get(`${this.API_URI}/proveedores-imagenes`);
  }

  crearImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/proveedores-imagenes`, datos);
  }

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/proveedores-imagenes/imagenes/${id}`);
  }

  //PROVEEDORES

  getProvedores(){
    return this.http.get(`${this.API_URI}/proveedores`)
  }

  getProveedor(id:string){
    return this.http.get(`${this.API_URI}/proveedores/${id}`);
  }

  actualizarProveedor(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/proveedores/${id}`, datos);
  }
  
  crearProveedor(datos: any) {
    return this.http.post(`${this.API_URI}/proveedores`, datos);
  }

  eliminarProveedor(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/proveedores/${id}`);
  }

}
