import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GaleriaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  // getGaleriasImagenes(){
  //   return this.http.get(`${this.API_URI}/galeria`)
  // }

  getGaleriaPorClasificacion(id:string){
    return this.http.get(`${this.API_URI}/galeria/porClasificacion/${id}`);
  }

  getGaleria(id:string){
    return this.http.get(`${this.API_URI}/galeria/${id}`);
  }

  actualizarGaleria(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/galeria/${id}`, datos);
  }
  
  crearGaleria(datos: any) {
    return this.http.post(`${this.API_URI}/galeria`, datos);
  }

  eliminarGaleria(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/galeria/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/galeria/estadoImagen/${id}`, datos);
  }

}
