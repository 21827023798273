import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ContactoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getContactos() {
    return this.http.get(`${this.API_URI}/contacto`)
  }

  getContacto(id: string) {
    return this.http.get(`${this.API_URI}/contacto/${id}`);
  }

  actualizarContacto(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/contacto/${id}`, datos);
  }

  crearContacto(datos: any) {
    return this.http.post(`${this.API_URI}/contacto`, datos);
  }

  eliminarContacto(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/contacto/${id}`);
  }

  //CORREOS

  getCorreos(id: string) {
    return this.http.get(`${this.API_URI}/contacto/correos/${id}`);
  }

  actualizarCorreos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/contacto/correos/${id}`, datos);
  }

  crearCorreos(datos: any) {
    return this.http.post(`${this.API_URI}/contacto/correos/crear`, datos);
  }

  eliminarCorreos(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/contacto/correos/${id}`);
  }

  //TELEFONOS

  getTelefonos(id: string) {
    return this.http.get(`${this.API_URI}/contacto/telefonos/${id}`);
  }

  actualizarTelefonos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/contacto/telefonos/${id}`, datos);
  }

  crearTelefonos(datos: any) {
    return this.http.post(`${this.API_URI}/contacto/telefonos/crear`, datos);
  }

  eliminarTelefonos(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/contacto/telefonos/${id}`);
  }

  //HORARIOS

  getHorarios(id: string) {
    return this.http.get(`${this.API_URI}/contacto/horarios/${id}`);
  }

  actualizarHorarios(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/contacto/horarios/${id}`, datos);
  }

  crearHorarios(datos: any) {
    return this.http.post(`${this.API_URI}/contacto/horarios/crear`, datos);
  }

  eliminarHorarios(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/contacto/horarios/${id}`);
  }
}
