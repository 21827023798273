export const CorreoTable = {
    titulo:"text",
    correo:"text",
    isEdit: "isEdit"
}

export const TelefonoTable = {
    telefono:"text",
    isEdit: "isEdit"
}

export const HorarioTable = {
    dias:"text",
    hora:"text",
    isEdit: "isEdit"
}
