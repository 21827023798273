import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CorreoTable, HorarioTable, TelefonoTable } from 'app/model/contacto';
import { ContactoService } from 'app/services/contacto/contacto.service';
import { DataService } from 'app/services/secciones.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contacto-detalle',
  templateUrl: './contacto-detalle.component.html',
  styleUrls: ['./contacto-detalle.component.scss']
})
export class ContactoDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA CONTACTO QUE EL USUARIO SELECCIONO 
  idContacto: string = this.obtenerId().idContacto;

  //VARIABLES
  edit: boolean = false;
  correos: any = [];
  telefonos: any = [];
  horarios: any = [];

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsCorreo: string[] = Object.keys(CorreoTable);
  dataSchemaCorreo = CorreoTable;
  dataSourceCorreo = [...this.correos];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  displayedColumnsTelefono: string[] = Object.keys(TelefonoTable);
  dataSchemaTelefono = TelefonoTable;
  dataSourceTelefono = [...this.telefonos];
  @ViewChild('tableB', { static: true }) tableB: MatTable<any>;

  displayedColumnsHorario: string[] = Object.keys(HorarioTable);
  dataSchemaHorario = HorarioTable;
  dataSourceHorario = [...this.horarios];
  @ViewChild('tableC', { static: true }) tableC: MatTable<any>;


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ContactoForm = new FormGroup({

    titulo: new FormControl('', Validators.required),
    ubicacion: new FormControl('', Validators.required),
    maps: new FormControl('', Validators.required)

  });

  CorreoForm = new FormGroup({

    titulo: new FormControl('', Validators.required),
    correo: new FormControl('', Validators.required),
    idContacto: new FormControl('')

  });

  TelefonoForm = new FormGroup({

    telefono: new FormControl('', Validators.required),
    idContacto: new FormControl('')

  });

  HorarioForm = new FormGroup({

    dias: new FormControl('', Validators.required),
    hora: new FormControl('', Validators.required),
    idContacto: new FormControl('')

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciocontacto: ContactoService,
  ) { }

  ngOnInit(): void {
    if (this.idContacto) {
      this.obtenerCorreos(this.idContacto)
      this.obtenerTelefonos(this.idContacto)
      this.obtenerHorarios(this.idContacto)
      this.serviciocontacto.getContacto(this.idContacto).subscribe(response => {
        this.ContactoForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ContactoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let result: any = {};
    let id;

    this.serviciocontacto.crearContacto(this.ContactoForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;
      if (this.correos.length > 0) {
        this.correos.forEach(correo => {
          delete correo.isEdit;
          correo.idContacto = id;
          this.serviciocontacto.crearCorreos(correo).subscribe(response => {
          });
        });
      }
      if (this.telefonos.length > 0) {
        this.telefonos.forEach(telefono => {
          delete telefono.isEdit;
          telefono.idContacto = id;
          this.serviciocontacto.crearTelefonos(telefono).subscribe(response => {
          });
        });
      }
      if (this.horarios.length > 0) {
        this.horarios.forEach(horario => {
          delete horario.isEdit;
          horario.idContacto = id;
          this.serviciocontacto.crearHorarios(horario).subscribe(response => {
          });
        });
      }

      this.ContactoForm.markAsPristine()
      this.ContactoForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/contactos')
    });

  }

  actualizarDatos = () => {

    this.serviciocontacto.actualizarContacto(this.idContacto, this.ContactoForm.value).subscribe(response => {

      if (this.correos.length > 0) {
        this.correos.forEach(correo => {
          delete correo.isEdit;
          correo.idContacto = this.idContacto;
          this.serviciocontacto.crearCorreos(correo).subscribe(response => {
          });
        });
      }
      if (this.telefonos.length > 0) {
        this.telefonos.forEach(telefono => {
          delete telefono.isEdit;
          telefono.idContacto = this.idContacto;
          this.serviciocontacto.crearTelefonos(telefono).subscribe(response => {
          });
        });
      }
      if (this.horarios.length > 0) {
        this.horarios.forEach(horario => {
          delete horario.isEdit;
          horario.idContacto = this.idContacto;
          this.serviciocontacto.crearHorarios(horario).subscribe(response => {
          });
        });
      }

      this.ContactoForm.markAsPristine()
      this.ContactoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/contactos')
    });
  }

  //SUBTABLAS DE DATOS DE CONTACTO
  //CORREOS
  obtenerCorreos = (id) => {
    let array: any = []
    this.dataSourceCorreo.length = 0;
    this.serviciocontacto.getCorreos(id).subscribe(response => {
      array = response;
      array.forEach(floracion => {
        this.dataSourceCorreo.push(floracion)
        this.tableA.renderRows()
      });
    });
  }

  editarCorreo(row) {
    if (row.id === 0) {
    } else {
      if (row.idCorreo) {
        delete row.isEdit
        this.serviciocontacto.actualizarCorreos(row.idCorreo, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerCorreos(this.idContacto)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  guardarCorreo = () => {
    if (this.CorreoForm.get('titulo').value != null) {
      this.dataSourceCorreo.push(this.CorreoForm.value)
      this.correos.push(this.CorreoForm.value)
      this.tableA.renderRows()
      this.CorreoForm.reset()
    }
  }


  eliminarCorreo(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciocontacto.eliminarCorreos(id).subscribe(() => {

          this.obtenerCorreos(this.idContacto)
        })
      }
    })
  }

  //TELEFONOS

  obtenerTelefonos = (id) => {
    let array: any = []
    this.dataSourceTelefono.length = 0;
    this.serviciocontacto.getTelefonos(id).subscribe(response => {
      array = response;
      array.forEach(element => {
        this.dataSourceTelefono.push(element)
        this.tableB.renderRows()
      });
    });
  }

  editarTelefono(row) {
    if (row.id === 0) {
    } else {
      if (row.idTelefono) {
        delete row.isEdit
        this.serviciocontacto.actualizarTelefonos(row.idTelefono, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerTelefonos(this.idContacto)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  guardarTelefono = () => {
    if (this.TelefonoForm.get('telefono').value != null) {
      this.dataSourceTelefono.push(this.TelefonoForm.value)
      this.telefonos.push(this.TelefonoForm.value)
      this.tableB.renderRows()
      this.TelefonoForm.reset()
    }
  }


  eliminarTelefono(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciocontacto.eliminarTelefonos(id).subscribe(() => {

          this.obtenerTelefonos(this.idContacto)
        })
      }
    })
  }


  //HORARIOS

  obtenerHorarios = (id) => {
    let array: any = []
    this.dataSourceHorario.length = 0;
    this.serviciocontacto.getHorarios(id).subscribe(response => {
      array = response;
      array.forEach(element => {
        this.dataSourceHorario.push(element)
        this.tableC.renderRows()
      });
    });
  }

  editarHorario(row) {
    if (row.id === 0) {
    } else {
      if (row.idHorario) {
        delete row.isEdit
        this.serviciocontacto.actualizarHorarios(row.idHorario, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerHorarios(this.idContacto)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  guardarHorario = () => {
    if (this.HorarioForm.get('dias').value != null) {
      this.dataSourceHorario.push(this.HorarioForm.value)
      this.horarios.push(this.HorarioForm.value)
      this.tableC.renderRows()
      this.HorarioForm.reset()
    }
  }


  eliminarHorario(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciocontacto.eliminarHorarios(id).subscribe(() => {

          this.obtenerHorarios(this.idContacto)
        })
      }
    })
  }

}
