import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { FloracionService } from 'app/services/floraciones/floracion.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-floracion-imagen',
  templateUrl: './floracion-imagen.component.html',
  styleUrls: ['./floracion-imagen.component.scss']
})
export class FloracionImagenComponent implements OnInit {

  FloracionForm = new FormGroup({
    rutaImagen: new FormControl('', [Validators.required])
  });
  
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen: any = {};
  constructor(private serviciofloracion: FloracionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    this.obtenerImagen()
  }

  obtenerImagen = () => {
    this.serviciofloracion.getFloracionImagen(1).subscribe(response => {
      this.imagen = response[0].rutaImagen;
      this.FloracionForm.patchValue(response[0])
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.FloracionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.FloracionForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.FloracionForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  eliminarImagen = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.serviciofloracion.actualizarEstadoImagen(1, this.FloracionForm.value).subscribe(response=>{
       this.serviciodocumento.eliminarImagen(this.imagen).subscribe(response =>{

       });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
  
    })
  }


  guardarDatos = () => {

    let imagen = this.FloracionForm.get('rutaImagen').value;

    if (this.FloracionForm.get('rutaImagen').value) {
      this.FloracionForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }
    this.serviciofloracion.actualizarFloracionImagen(1, this.FloracionForm.value).subscribe(response => {
      if (this.FloracionForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }
      this.FloracionForm.markAsPristine()
      this.FloracionForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
      this.ngOnInit()
      
    })

  }

}
