import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ValoresService } from 'app/services/inicio/valores.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-valores-corporativos-detalle',
  templateUrl: './valores-corporativos-detalle.component.html',
  styleUrls: ['./valores-corporativos-detalle.component.scss']
})
export class ValoresCorporativosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idValor: string = this.obtenerId().idValor;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_icono: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ValoresForm = new FormGroup({

    nombre: new FormControl('', Validators.required),
    contenido: new FormControl('', Validators.required)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciovalores: ValoresService,
    ) { }

  ngOnInit(): void {
    if (this.idValor) {
      this.serviciovalores.getValor(this.idValor).subscribe(response => {
        this.ValoresForm.patchValue(response[0])
        this.imagen_icono = response[0].icono;

      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ValoresForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    this.serviciovalores.crearValor(this.ValoresForm.value).subscribe(response =>{
      
      this.ValoresForm.markAsPristine()
      this.ValoresForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/valores')
    });

  }

  actualizarDatos = () => {

    this.serviciovalores.actualizarValor(this.idValor, this.ValoresForm.value).subscribe(response =>{

      this.ValoresForm.markAsPristine()
      this.ValoresForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/valores')
    });
  }

}
