import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { AvisoPrivacidadService } from 'app/services/avisos-privacidad.service';
import { DocumentService } from 'app/services/documentos.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-aviso-privacidad-detalle',
  templateUrl: './aviso-privacidad-detalle.component.html',
  styleUrls: ['./aviso-privacidad-detalle.component.scss']
})
export class AvisoPrivacidadDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idAviso: string = this.obtenerId().idAviso;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  AvisoPDF: any = '';
  imagenes: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  AvisoPrivacidadForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    estado: new FormControl(1),
    rutaDocumento: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioaviso: AvisoPrivacidadService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    if (this.idAviso) {
      this.servicioaviso.getAvisoPrivacidad(this.idAviso).subscribe(response => {
        this.AvisoPrivacidadForm.patchValue(response[0])
        this.AvisoPDF = response[0].rutaDocumento;
      })
    }

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.AvisoPrivacidadForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  guardarDatos = () => {

    let result: any = {};

    let pdf = this.AvisoPrivacidadForm.get('rutaDocumento').value;
    if (this.AvisoPrivacidadForm.get('rutaDocumento').value) {
      this.AvisoPrivacidadForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicioaviso.crearAvisoPrivacidad(this.AvisoPrivacidadForm.value).subscribe(response => {
      result = response;
      if (this.AvisoPrivacidadForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.AvisoPrivacidadForm.markAsPristine()
      this.AvisoPrivacidadForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/avisos-privacidad')
    });
  }

  actualizarDatos = () => {

    let pdf = this.AvisoPrivacidadForm.get('rutaDocumento').value;
    if (this.AvisoPrivacidadForm.get('rutaDocumento').value) {
      this.AvisoPrivacidadForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.servicioaviso.actualizarAvisoPrivacidad(this.idAviso, this.AvisoPrivacidadForm.value).subscribe(response => {
      if (this.AvisoPrivacidadForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.AvisoPrivacidadForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/avisos-privacidad')
    });
  }


  guardarPDF = (pdf: any) => {
    this.AvisoPrivacidadForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.AvisoPrivacidadForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioaviso.actualizarEstadoDocumento(this.idAviso, this.AvisoPrivacidadForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

}
