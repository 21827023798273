import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { GruposPErmisosService } from 'app/services/configuracion/grupos-permisos.service';
import { DataService } from 'app/services/secciones.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-grupos-permisos-detalle',
  templateUrl: './grupos-permisos-detalle.component.html',
  styleUrls: ['./grupos-permisos-detalle.component.scss']
})
export class GruposPermisosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idGrupo: string = this.obtenerId().idGrupo;

  //VARIABLES
  edit: boolean = false;
  secciones: any = [];
  accesos: any = [];
  eliminar: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  GrupoForm = new FormGroup({
    nombre: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: GruposPErmisosService) { }

  ngOnInit(): void {
    this.obtenerSecciones()
    if (this.idGrupo) {
      this.obtenerPermisos()
      this.catalogoservice.getGrupoPermiso(this.idGrupo).subscribe(response => {
        this.GrupoForm.patchValue(response[0])
      });
    }
  }

  obtenerPermisos = () => {
    this.catalogoservice.getPermisosUsuario(this.idGrupo).subscribe(response => {
      this.accesos = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.GrupoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  obtenerSecciones = () => {
    let array: any = [];
    let i = 0;
    this.catalogoservice.getSecciones().subscribe(response => {
      if (this.idGrupo) {
        this.catalogoservice.getSeccionesRestantes(this.idGrupo).subscribe(res => {
          if (res) {
            this.secciones = res;
          }
        });
      } else {
        this.secciones = response;
      }
    })
  }

  guardarDatos = () => {
    let result: any = {};
    let id;
    this.catalogoservice.crearGrupoPermiso(this.GrupoForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;
      this.GrupoForm.reset()
      if (this.accesos.length >= 1) {
        this.accesos.forEach(element => {
          delete element.icon;
          delete element.label;
          delete element.link;
          element.idGrupo = id;
          this.catalogoservice.crearSeccion(element).subscribe(response => {

          });
        });
      }
      this.openSnackBar("GRUPO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/grupos-permisos')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarGrupoPermiso(this.idGrupo, this.GrupoForm.value).subscribe(response => {

      if (this.accesos.length >= 1) {

        this.secciones.forEach(seccion => {
          if (seccion.idPermiso) {
            this.catalogoservice.eliminarPermiso(seccion.idPermiso).subscribe(response => {

            })
          }
        });

        this.accesos.forEach(element => {
          if (element.idPermiso) {
            delete element.label;
            element.idGrupo = this.idGrupo;
            this.catalogoservice.actualizarSeccion(element.idPermiso, element).subscribe(response => {
            })
          } else {
            delete element.icon;
            delete element.label;
            delete element.link;
            element.idGrupo = this.idGrupo;
            this.catalogoservice.crearSeccion(element).subscribe(response => {

            });
          }
        });
      }
      this.GrupoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/grupos-permisos')
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}
