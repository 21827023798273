import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RedesSocialesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getRedesSociales(id:number){
    return this.http.get(`${this.API_URI}/redes-sociales/${id}`);
  }

  actualizarRedesSociales(id: number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/redes-sociales/${id}`, sede);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/redes-sociales/estadoImagen/${id}`, datos);
  }

}
