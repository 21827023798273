import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PortadaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getPortadaDatos(id:string | number){
    return this.http.get(`${this.API_URI}/portada/${id}`);
  }

  actualizarPortada(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/portada/${id}`, datos);
  }
  
  crearPortada(datos: any) {
    return this.http.post(`${this.API_URI}/portada`, datos);
  }

  eliminarPortada(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/portada/${id}`);
  }

  actualizarEstadoImagenLogo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/portada/logo/${id}`, datos);
  }

  actualizarEstadoImagenPortada(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/portada/portada/${id}`, datos);
  }

  getImagenes(){
    return this.http.get(`${this.API_URI}/portada`);
  }

  crearImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/portada/portada/imagenes`, datos);
  }

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/portada/portada/${id}`);
  }

  actualizarDatoNosotrosImagenes(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/portada/actualizarportada/${id}`, datos);
  }
}
