import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SociedadService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSociedadContenido(id:number){
    return this.http.get(`${this.API_URI}/sociedad-contenido/${id}`);
  }

  actualizarSociedadContenido(id: number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sociedad-contenido/${id}`, sede);
  }


  //MODULO DE AVISOS EMPRESA

  getSociedades(){
    return this.http.get(`${this.API_URI}/sociedad`)
  }

  getSociedad(id:string){
    return this.http.get(`${this.API_URI}/sociedad/${id}`);
  }

  actualizarSociedad(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sociedad/${id}`, datos);
  }
  
  crearSociedad(datos: any) {
    return this.http.post(`${this.API_URI}/sociedad`, datos);
  }

  eliminarSociedad(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sociedad/${id}`);
  }

    //IMAGENES POR EMPRESA

    getImagenes(id:string){
      return this.http.get(`${this.API_URI}/sociedad/imagenes/${id}`);
    }
  
    crearImagenes(datos: any) {
      return this.http.post(`${this.API_URI}/sociedad/imagenes/porSociedad`, datos);
    }
  
    eliminarImagen(id: string): Observable<any> {
      return this.http.delete(`${this.API_URI}/sociedad/imagenes/${id}`);
    }
  
    actualizarSociedadImagen(id: string | number, datos: any): Observable<any> {
      return this.http.put(`${this.API_URI}/sociedad/imagenes/${id}`, datos);
    }

}
