import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { DataService } from 'app/services/secciones.service';
import { SociedadService } from 'app/services/vinculacion/sociedad/sociedad.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sociedad-detalle',
  templateUrl: './sociedad-detalle.component.html',
  styleUrls: ['./sociedad-detalle.component.scss']
})
export class SociedadDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idSociedad: string = this.obtenerId().idSociedad;

  //VARIABLES
  edit: boolean = false;
  empresas_avisos: any = []
  array: any = [];
  imagenes: any = [];
  IMAGEN_HOST = environment.API_IMAGEN;
  labelPosition:number = 1

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 

  SociedadForm = new FormGroup({
    fecha: new FormControl(new Date, Validators.required),
    titulo: new FormControl('', Validators.required),
    contenido: new FormControl('', Validators.required),
    orden: new FormControl(0),
    estado: new FormControl('')
  });

  ImagenesForm = new FormGroup({
    idSociedad: new FormControl(''),
    rutaImagen: new FormControl(null),
    principal: new FormControl(0)
  })

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioSociedad: SociedadService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idSociedad) {
      this.obtenerImagenes()
      this.servicioSociedad.getSociedad(this.idSociedad).subscribe(response => {
        this.SociedadForm.patchValue(response[0])
      });
    }
  }

  obtenerImagenes = () => {
    this.servicioSociedad.getImagenes(this.idSociedad).subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SociedadForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let result: any = {};
    let id;
    let datos: any = [];
    let imagen = this.ImagenesForm.get('rutaImagen').value;
    this.SociedadForm.controls['fecha'].setValue(moment(this.SociedadForm.get('fecha').value).format('YYYY-MM-DD'));   

    this.servicioSociedad.getSociedades().subscribe(response =>{
      datos = response;
      if(response){
        this.SociedadForm.controls['orden'].setValue(datos.length + 1)
        this.servicioSociedad.crearSociedad(this.SociedadForm.value).subscribe(response => {
          result = response;
          id = result.reps[0].id;
    
        if(this.ImagenesForm.get('rutaImagen').value){
          imagen._files.forEach(element => {
            this.ImagenesForm.get('rutaImagen').setValue(element.name)
            this.ImagenesForm.get('idSociedad').setValue(id)
            this.servicioSociedad.crearImagenes(this.ImagenesForm.value).subscribe(response => {
              this.guardarImagenPHP(imagen);
            })
          });
        }
          this.SociedadForm.markAsPristine()
          this.SociedadForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/sociedad')
        });
      }
    })


  }

  actualizarDatos = () => {

    let imagen = this.ImagenesForm.get('rutaImagen').value;
    this.SociedadForm.controls['fecha'].setValue(moment(this.SociedadForm.get('fecha').value).format('YYYY-MM-DD'));   
    if(this.ImagenesForm.get('rutaImagen').value){
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.ImagenesForm.get('idSociedad').setValue(this.idSociedad)
        this.servicioSociedad.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
        })
      });
    }

    this.servicioSociedad.actualizarSociedad(this.idSociedad, this.SociedadForm.value).subscribe(response => {

      this.SociedadForm.markAsPristine()
      this.SociedadForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/sociedad')
    });
  }

    //GUARDAR IMAGEN EN PROYECTO
    guardarImagenPHP = (imagen: any) => {
      imagen._files.forEach(element => {
        this.ImagenesForm.controls['rutaImagen'].setValue(element);
        const imgData = new FormData();
        imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
        this.serviciodocumento.guardarImg(imgData).subscribe(response => {
        });
      });
  
    }
  
    eliminarImagen = (id, name) =>{
      this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
        if (confirm) {
       this.servicioSociedad.eliminarImagen(id).subscribe(response=>{
         this.serviciodocumento.eliminarImagen(name).subscribe(response =>{
  
         });
        this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
        this.ngOnInit()
       })
        }
    
      })
    }

    radioSelected = (datos) =>{

      this.imagenes.forEach(element => {
        if(element.principal == 1){
          element.principal = 0;
          this.servicioSociedad.actualizarSociedadImagen(element.idImagen, element).subscribe(response =>{
          });
        }
      });
      datos.principal = 1;
      this.servicioSociedad.actualizarSociedadImagen(datos.idImagen, datos).subscribe(response =>{
        this.openSnackBar('El estado se ha actualizado!!')
        this.obtenerImagenes()
        this.labelPosition = 1
      });
    }


}
