import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/login.service';
import { DataService } from 'app/services/secciones.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'DASHBOARD',  icon: 'pe-7s-graph', class: '' },
    { path: '/datosEmpresa', title: 'DATOS EMPRESA',  icon:'pe-7s-culture', class: '' },
    { path: '/productos', title: 'PRODUCTOS',  icon:'pe-7s-note2', class: '' },
    { path: '/typography', title: 'Typography',  icon:'pe-7s-news-paper', class: '' },
    { path: '/icons', title: 'Icons',  icon:'pe-7s-science', class: '' },
    { path: '/maps', title: 'Maps',  icon:'pe-7s-map-marker', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'pe-7s-bell', class: '' },
    { path: '', title: 'Cerrar sesión',  icon:'pe-7s-left-arrow', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  appitems: any = [];
  dataUsuario;

  constructor(private dataService: DataService, private loginCtrl: LoginService) { }

  menuInicio: any = [
    {
      label: 'Slider',
      link: '/portada',
      icon: 'perm_media'
    }, 
    {
      label: 'Bienvenida',
      link: '/bienvenida',
      icon: 'home'
    }, 
    {
      label: 'Nosotros',
      link: '/nosotros',
      icon: 'groups'
    }, 
    {
      label: 'Valores corporativos',
      link: '/valores',
      icon: 'front_hand'
    }

  ]

  menuFloraciones: any = [
    {
      label: 'Imagen de Sección',
      link: '/floracion-imagen',
      icon: 'add_photo_alternate'
    }, 
    {
      label: 'Floraciones',
      link: '/floraciones',
      icon: 'emoji_nature'
    }
  ]

  menuProductos: any = [
    {
      label: 'Marcas Productos',
      link: '/marcas',
      icon: 'receipt'
    },
    {
      label: 'Productos',
      link: '/productos',
      icon: 'inventory'
    }, 
    {
      label: 'Clasificaciones',
      link: '/clasificaciones',
      icon: 'receipt'
    }
  ]

  menuProveedores: any = [
    {
      label: 'Imágenes',
      link: '/proveedores-imagen',
      icon: 'add_photo_alternate'
    }, 
    {
      label: 'Proveedores',
      link: '/proveedores',
      icon: 'groups'
    }
  ]

  menuVinculacion: any = [
    
    {
      label: 'Avisos',
      icon: 'chat_bubble',
      items: [{
        label: 'Contenido',
        link: '/avisos-contenido',
        icon: 'feed'
      },
      {
        label: 'Avisos',
        link: '/avisos',
        icon: 'note_add'
      },
    ]
    }, 
    {
      label: 'Empresa',
      icon: 'contacts',
      items: [{
        label: 'Contenido',
        link: '/empresa-contenido',
        icon: 'feed'
      },
      {
        label: 'Empresa',
        link: '/empresa',
        icon: 'note_add'
      },
    ]
    },
    {
      label: 'Sociedad',
      icon: 'voice_chat',
      items: [{
        label: 'Contenido',
        link: '/sociedad-contenido',
        icon: 'feed'
      },
      {
        label: 'Sociedad',
        link: '/sociedad',
        icon: 'note_add'
      },
    ]
    }
  ]

  menuGaleria: any = [
    {
      label: 'Galería',
      link: '/galeria',
      icon: 'photo_library'
    }, 
    {
      label: 'Clasificaciones',
      link: '/clasificaciones-galeria',
      icon: 'receipt'
    }
  ]

  menuContacto: any = [

    {
      label: 'Contacto',
      link: '/contactos',
      icon: 'call'
    },
    {
      label: 'Redes sociales',
      link: '/redes-sociales',
      icon: 'share'
    }
  ]

  menuConfiguracion: any = [
    {
      label: 'Grupos Permisos',
      link: '/grupos-permisos',
      icon: 'manage_accounts'
    },
    {
      label: 'Usuarios',
      link: '/usuarios',
      icon: 'manage_accounts'
    }
  ]


  ngOnInit() {

    this.loginCtrl.obtenerInfoUsuario().subscribe(response => {
      this.dataUsuario = response;
    });
    this.obtenerMenus()
    this.menuItems = ROUTES.filter(menuItem => menuItem);

  }

  obtenerMenus = () => {
    this.dataService.getMenu(this.dataUsuario.idGrupo).subscribe(response => {
      this.appitems = response;
      this.appitems.forEach(seccion => {
        if (seccion.link) {
        } else {
          seccion.items = []
          if (seccion.idSeccion == 1) {
            seccion.items = this.menuInicio;
          } else if (seccion.idSeccion == 3) {
            seccion.items = this.menuFloraciones;
          } else if (seccion.idSeccion == 4) {
            seccion.items = this.menuProductos;
          } else if (seccion.idSeccion == 5) {
            seccion.items = this.menuProveedores;
          } else if (seccion.idSeccion == 6) {
            seccion.items = this.menuVinculacion;
          } else if (seccion.idSeccion == 7) {
            seccion.items = this.menuGaleria;
          } else if (seccion.idSeccion == 8) {
            seccion.items = this.menuContacto;
          }  else if (seccion.idSeccion == 11) {
            seccion.items = this.menuConfiguracion;
          } 
        }
      });
    });

  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
