import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BienvenidaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getBienvenida(id:number){
    return this.http.get(`${this.API_URI}/bienvenida/${id}`);
  }

  actualizarBienvenida(id: number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/bienvenida/${id}`, sede);
  }
  
  getImagenes(){
    return this.http.get(`${this.API_URI}/bienvenida`);
  }

  crearImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/bienvenida`, datos);
  }

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/bienvenida/imagenes/${id}`);
  }

  actualizarDatoNosotrosImagenes(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/bienvenida/imagenes/${id}`, datos);
  }

}
