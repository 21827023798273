import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CertificadoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCertificados(){
    return this.http.get(`${this.API_URI}/certificados`)
  }

  getCertficado(id:string){
    return this.http.get(`${this.API_URI}/certificados/${id}`);
  }

  actualizarCertficado(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/certificados/${id}`, datos);
  }
  
  crearCertficado(datos: any) {
    return this.http.post(`${this.API_URI}/certificados`, datos);
  }

  eliminarCertficado(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/certificados/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/certificados/imagen/${id}`, datos);
  }

}
