import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { PortadaService } from 'app/services/portada.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.scss']
})
export class PortadaComponent implements OnInit {

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_logo: any = '';
  imagen_port: any = '';
  imagenes: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  PortadaForm = new FormGroup({
    encabezado: new FormControl(''),
    descripcion: new FormControl(''),
    logo: new FormControl(1),
    imagen_portada: new FormControl(1)
  });

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null),
  })

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private portadaservice: PortadaService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    this.obtenerDatos()
    this.obtenerImagenes()

  }

  obtenerDatos = () => {
    this.portadaservice.getPortadaDatos(1).subscribe(response => {
      this.PortadaForm.patchValue(response[0])
      this.imagen_logo = response[0].logo;
      this.imagen_port = response[0].imagen_portada;
    })
  }

  obtenerImagenes = () => {
    this.portadaservice.getImagenes().subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.PortadaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    // let imagen = this.PortadaForm.get('rutaImagen').value;
    // this.PortadaForm.controls['rutaImagen'].setValue(imagen._fileNames)

    // this.portadaservice.crearPortada(this.PortadaForm.value).subscribe(response => {
    //   if (this.PortadaForm.get('rutaImagen').value) {
    //     this.guardarImagenPHP(imagen)
    //   }this.PortadaForm.reset()
    //   this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
    // this.router.navigateByUrl('/organismos-nal-intl')
    // });
  }

  actualizarDatos = () => {
    let imagen = this.PortadaForm.get('logo').value;
    let imagenPortada = this.PortadaForm.get('imagen_portada').value;

    let imagen_portada = this.ImagenesForm.get('rutaImagen').value;

    if(this.ImagenesForm.get('rutaImagen').value){
      imagen_portada._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.portadaservice.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenEnPHP(imagen_portada);
        })
      });
    }

    if (this.PortadaForm.get('logo').value) {
      this.PortadaForm.controls['logo'].setValue(imagen._fileNames)
    }

    if (this.PortadaForm.get('imagen_portada').value) {
      this.PortadaForm.controls['imagen_portada'].setValue(imagenPortada._fileNames)
    }

    this.portadaservice.actualizarPortada(1, this.PortadaForm.value).subscribe(response => {
      if (this.PortadaForm.get('logo').value) {
        this.guardarImagenPHP(imagen)
      }
      if (this.PortadaForm.get('imagen_portada').value) {
        this.guardarImagenPHP(imagenPortada)
      }

      this.PortadaForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.ngOnInit()
      // this.router.navigateByUrl('/organismos-nal-intl')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    if (this.PortadaForm.get('logo').value) {

      this.PortadaForm.controls['logo'].setValue(imagen._files[0]);
      const imgData = new FormData();
      imgData.append('imagen', this.PortadaForm.get('logo').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    }
    if (this.PortadaForm.get('imagen_portada').value) {

      this.PortadaForm.controls['imagen_portada'].setValue(imagen._files[0]);
      const imgData = new FormData();
      imgData.append('imagen', this.PortadaForm.get('imagen_portada').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    }

  }

  eliminarImagenLogo = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.portadaservice.actualizarEstadoImagenLogo(1, this.PortadaForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen_logo).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }

    //GUARDAR IMAGEN EN PROYECTO
    guardarImagenEnPHP = (imagen: any) => {
      imagen._files.forEach(element => {
        this.ImagenesForm.controls['rutaImagen'].setValue(element);
        const imgData = new FormData();
        imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
        this.serviciodocumento.guardarImg(imgData).subscribe(response => {
        });
      });
  
    }
  
    eliminarImagen = (id, name) =>{
      this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
        if (confirm) {
       this.portadaservice.eliminarImagen(id).subscribe(response=>{
         this.serviciodocumento.eliminarImagen(name).subscribe(response =>{
  
         });
        this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
        this.ngOnInit()
       })
        }
    
      })
    }

    drop(event: CdkDragDrop<string[]>) {
      let i = 1;
  
      moveItemInArray(this.imagenes, event.previousIndex, event.currentIndex);
  
      this.imagenes.reverse()
      this.imagenes.forEach(dato => {
        dato.orden = i;
  
        this.portadaservice.actualizarDatoNosotrosImagenes(dato.idSlider, dato).subscribe(response => {
        });
  
  
        i++;
      });
      this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
      this.imagenes.reverse()
  
    }

  // eliminarImagenPortada = () => {

  //   this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
  //     if (confirm) {
  //       this.portadaservice.actualizarEstadoImagenPortada(1, this.PortadaForm.value).subscribe(response => {
  //         this.serviciodocumento.eliminarImagen(this.imagen_port).subscribe(response => {

  //         });
  //         this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
  //         this.ngOnInit()
  //       })
  //     }
  //   })
  // }
}
