import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { ProveedoresService } from 'app/services/proveedores/proveedores.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-proveedores-imagenes',
  templateUrl: './proveedores-imagenes.component.html',
  styleUrls: ['./proveedores-imagenes.component.scss']
})
export class ProveedoresImagenesComponent implements OnInit {

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null, Validators.required),
  })
  
  IMAGEN_HOST = environment.API_IMAGEN;
  imagenes: any = [];
  constructor(private servicioproveedores: ProveedoresService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    this.obtenerImagenes()
  }

  obtenerImagenes = () => {
    this.servicioproveedores.getImagenes().subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ImagenesForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  eliminarImagen = (id, name) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicioproveedores.eliminarImagen(id).subscribe(response=>{
       this.serviciodocumento.eliminarImagen(name).subscribe(response =>{

       });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
  
    })
  }

  guardarDatos = () => {

    let imagen = this.ImagenesForm.get('rutaImagen').value;

    if(this.ImagenesForm.get('rutaImagen').value){
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.servicioproveedores.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
          this.ImagenesForm.reset()
          this.openSnackBar('REGISTRO ACTUALIZADO!');
          this.ngOnInit()
        })
      });
    }

  }
}
