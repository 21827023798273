import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AvisosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getAvisoContenido(id:number){
    return this.http.get(`${this.API_URI}/avisos-contenido/${id}`);
  }

  actualizarAvisoContenido(id: number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/avisos-contenido/${id}`, sede);
  }


  //MODULO DE AVISOS

  getAvisos(){
    return this.http.get(`${this.API_URI}/avisos`)
  }

  getAviso(id:string){
    return this.http.get(`${this.API_URI}/avisos/${id}`);
  }

  actualizarAviso(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/avisos/${id}`, datos);
  }
  
  crearAviso(datos: any) {
    return this.http.post(`${this.API_URI}/avisos`, datos);
  }

  eliminarAviso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/avisos/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/avisos/estadoImagen/${id}`, datos);
  }

}
