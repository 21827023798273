import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsuariosService } from 'app/services/configuracion/usuarios.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  displayedColumns: string[] = ['idUsuario', 'usuario', 'nombre', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public categorias: any = [];

  constructor(private serviciousuarios: UsuariosService) { }

  ngOnInit(): void {
    this.obtenerCategorias()
  }

  obtenerCategorias = () =>{
    this.serviciousuarios.getUsuarios().subscribe(response =>{
      this.categorias = response;
      this.dataSource = new MatTableDataSource(this.categorias);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
