import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { DataService } from 'app/services/secciones.service';
import { AvisosService } from 'app/services/vinculacion/avisos/avisos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-aviso-detalle',
  templateUrl: './aviso-detalle.component.html',
  styleUrls: ['./aviso-detalle.component.scss']
})
export class AvisoDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idAviso: string = this.obtenerId().idAviso;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_aviso: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  AvisoForm = new FormGroup({
    rutaImagen: new FormControl('', Validators.required),
    orden: new FormControl(0),
    estado: new FormControl(1),
    descripcion: new FormControl('',Validators.required), 
    titulo: new FormControl('', Validators.required), 
  });

  array: any = [];
  clasificaciones: any = []

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioavisos: AvisosService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idAviso) {
      this.servicioavisos.getAviso(this.idAviso).subscribe(response => {
        this.AvisoForm.patchValue(response[0])
        this.imagen_aviso = response[0].rutaImagen;

      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.AvisoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let datos: any = [];
    let imagen = this.AvisoForm.get('rutaImagen').value;

    if (this.AvisoForm.get('rutaImagen').value) {
      this.AvisoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioavisos.getAvisos().subscribe(response => {
      datos = response;
      if (response) {
        this.AvisoForm.controls['orden'].setValue(datos.length + 1)
        this.servicioavisos.crearAviso(this.AvisoForm.value).subscribe(response => {

          if (this.AvisoForm.get('rutaImagen').value) {
            this.guardarImagenPHP(imagen)
          }

          this.AvisoForm.markAsPristine()
          this.AvisoForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/avisos')
        });
      }
    })



  }

  actualizarDatos = () => {
    let imagen = this.AvisoForm.get('rutaImagen').value;

    if (this.AvisoForm.get('rutaImagen').value) {
      this.AvisoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioavisos.actualizarAviso(this.idAviso, this.AvisoForm.value).subscribe(response => {
      if (this.AvisoForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }
      this.AvisoForm.markAsPristine()
      this.AvisoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/avisos')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    this.AvisoForm.controls['rutaImagen'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.AvisoForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });

  }

  eliminarImagen = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioavisos.actualizarEstadoImagen(this.idAviso, this.AvisoForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen_aviso).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }


}
