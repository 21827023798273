import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { SociedadService } from 'app/services/vinculacion/sociedad/sociedad.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sociedad-contenido',
  templateUrl: './sociedad-contenido.component.html',
  styleUrls: ['./sociedad-contenido.component.scss']
})
export class SociedadContenidoComponent implements OnInit {

  SociedadForm = new FormGroup({
    contenido: new FormControl('', [Validators.required])
  });
  
  constructor(private servicioempresa: SociedadService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }


  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SociedadForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerDatos = () => {
    this.servicioempresa.getSociedadContenido(1).subscribe(response => {
      this.SociedadForm.patchValue(response[0]);
      this.SociedadForm.markAsPristine()
      this.SociedadForm.markAllAsTouched()
    })
  }


  guardarDatos = () => {

    this.servicioempresa.actualizarSociedadContenido(1, this.SociedadForm.value).subscribe(response => {
      this.SociedadForm.markAsPristine()
      this.SociedadForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
      this.ngOnInit()
    })

  }
}
