import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FloracionService } from 'app/services/floraciones/floracion.service';

@Component({
  selector: 'app-floracion',
  templateUrl: './floracion.component.html',
  styleUrls: ['./floracion.component.scss']
})
export class FloracionComponent implements OnInit {

  displayedColumns: string[] = ['idPeriodo', 'periodo', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private serviciovalores: FloracionService) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.serviciovalores.getPeriodosFloraciones().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
