import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ClasificacionProductosService } from 'app/services/productos/clasificacion.service';
import { ProductoMarcaService } from 'app/services/productos/marcas.service';
import { ProductosService } from 'app/services/productos/producto.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'nombre', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  idClasificacion: any = 1;
  idMarca: any = 1;
  clasificaciones: any = [];
  marcas: any = [];
  productos: any = [];

  constructor(private servicioproducto: ProductosService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioclasificacion: ClasificacionProductosService,
    private servicioMarcas: ProductoMarcaService,
    private route: ActivatedRoute, 
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.route.queryParams.filter(params => params.idMarca).subscribe(params =>{  
      if(params) {  
        this.idMarca = parseInt(params.idMarca);  
        this.idClasificacion = parseInt(params.idClasificacion);
    }
    }); 
    this.obtenerClasificaciones()
    this.obtenerMarcas()
    this.obtenerProductos()
  } 

  addProduct(){  
    console.log("añadiendo productos"); 
    this.router.navigate(['/producto-detalle'], {queryParams: {'idMarca': this.idMarca, 'idClasificacion': this.idClasificacion}}); 
  }

  obtenerProductos = () => {
    this.servicioproducto.getProductos(this.idClasificacion, this.idMarca).subscribe(response => {
      this.productos = response;
      this.dataSource = new MatTableDataSource(this.productos)
      this.dataSource.sort = this.sort
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarProducto = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioproducto.eliminarProducto(id).subscribe(cresponse => {
          this.obtenerProductos()
        });
      };
    });
  }; 

  obtenerClasificaciones = () => {
    this.servicioclasificacion.getClasificacionProductos().subscribe(response =>{ 
      this.clasificaciones = response;  
    })
  }

  obtenerMarcas = () =>{
    this.servicioMarcas.getCMarcas().subscribe(response =>{ 
      this.marcas = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  cambios = (event) => {
    this.obtenerProductos()
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.productos, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.productos);
    this.dataSource.sort = this.sort;
    this.productos.reverse()

    this.productos.forEach(clasificacion => {
      clasificacion.orden = i;

      if (clasificacion.estado == 'ACTIVO') {
        clasificacion.estado = 1;
      } else {
        clasificacion.estado = 0;
      }


      this.servicioproducto.actualizarProducto(clasificacion.idProducto, clasificacion).subscribe(response => {
      });


      if (clasificacion.estado == 1) {
        clasificacion.estado = 'ACTIVO';
      } else {
        clasificacion.estado = 'INACTIVO';
      }
      i++;
    });
    this.productos.reverse()

    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
