import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SeccionImagenService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSeccionesImagenes(){
    return this.http.get(`${this.API_URI}/imagenes`)
  }

  actualizarDatos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes/${id}`, datos);
  }

  getSeccionImagen(id:string){
    return this.http.get(`${this.API_URI}/imagenes/${id}`);
  }
  
  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes/estadoImagen/${id}`, datos);
  }

}
