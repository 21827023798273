import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class NosotrosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getNosotrosDatos(){
    return this.http.get(`${this.API_URI}/nosotros`)
  }

  getDatoNosotros(id:string){
    return this.http.get(`${this.API_URI}/nosotros/${id}`);
  }

  actualizarDatoNosotros(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/nosotros/${id}`, datos);
  }
  
  crearDatoNosotros(datos: any) {
    return this.http.post(`${this.API_URI}/nosotros`, datos);
  }

  eliminarDatoNosotros(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/nosotros/${id}`);
  }

  actualizarEstadoImagenIcono(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/nosotros/icono/${id}`, datos);
  }

}
