import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { NosotrosService } from 'app/services/inicio/nosotros.service';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {


  displayedColumns: string[] = ['orden','seccion','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public datos_nosotros: any = [];

  constructor(private serviceNosotros: NosotrosService, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.serviceNosotros.getNosotrosDatos().subscribe(response =>{
      this.datos_nosotros = response;
      this.dataSource = new MatTableDataSource(this.datos_nosotros)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarDato = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceNosotros.eliminarDatoNosotros(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.datos_nosotros, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.datos_nosotros);
    this.dataSource.sort = this.sort;
    this.datos_nosotros.reverse()
    this.datos_nosotros.forEach(dato => {
      dato.orden = i;

      if (dato.estado == 'ACTIVO') {
        dato.estado = 1;
      } else {
        dato.estado = 0;
      }

      this.serviceNosotros.actualizarDatoNosotros(dato.idSeccion, dato).subscribe(response => {
      });


      if (dato.estado == 1) {
        dato.estado = 'ACTIVO';
      } else {
        dato.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.datos_nosotros.reverse()
    // this.obtenerDatos()
  }

}
