import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { FloracionTable } from 'app/model/floracion';
import { DocumentService } from 'app/services/documentos.service';
import { FloracionService } from 'app/services/floraciones/floracion.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-floracion-detalle',
  templateUrl: './floracion-detalle.component.html',
  styleUrls: ['./floracion-detalle.component.scss']
})
export class FloracionDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idPeriodo: string = this.obtenerId().idPeriodo;

  //VARIABLES
  edit: boolean = false;
  floraciones: any = []
  array: any = [];
  imagenes: any = [];
  IMAGEN_HOST = environment.API_IMAGEN;

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsAudio: string[] = Object.keys(FloracionTable);
  dataSchemaAudio = FloracionTable;
  dataSourceAudio = [...this.floraciones];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  PeriodoForm = new FormGroup({

    periodo: new FormControl('', Validators.required),
    contenido: new FormControl('', Validators.required)

  });

  FloracionForm = new FormGroup({
    idPeriodo: new FormControl(''),
    nombre: new FormControl('', Validators.required),
    zona: new FormControl('', Validators.required),
    humedad: new FormControl('', Validators.required),
    color: new FormControl('', Validators.required)
  });

  ImagenesForm = new FormGroup({
    idPeriodo: new FormControl(''),
    rutaImagen: new FormControl(null),
  })

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciofloracion: FloracionService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idPeriodo) {
      this.obtenerFloraciones(this.idPeriodo)
      this.obtenerImagenes()
      this.serviciofloracion.getPeriodoFloracion(this.idPeriodo).subscribe(response => {
        this.PeriodoForm.patchValue(response[0])
      });
    }
  }

  obtenerImagenes = () => {
    this.serviciofloracion.getImagenes(this.idPeriodo).subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.PeriodoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let result: any = {};
    let id;
    let imagen = this.ImagenesForm.get('rutaImagen').value;


    this.serviciofloracion.crearPeriodoFloracion(this.PeriodoForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;

    if(this.ImagenesForm.get('rutaImagen').value){
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.ImagenesForm.get('idPeriodo').setValue(id)
        this.serviciofloracion.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
        })
      });
    }


      if (this.floraciones.length > 0) {
        this.floraciones.forEach(floracion => {
          delete floracion.isEdit;
          floracion.idPeriodo = id;
          this.serviciofloracion.crearFloraciones(floracion).subscribe(response => {
          });
        });
      }

      this.PeriodoForm.markAsPristine()
      this.PeriodoForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/floraciones')
    });

  }

  actualizarDatos = () => {

    let imagen = this.ImagenesForm.get('rutaImagen').value;

    if(this.ImagenesForm.get('rutaImagen').value){
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.ImagenesForm.get('idPeriodo').setValue(this.idPeriodo)
        this.serviciofloracion.crearImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
        })
      });
    }

    this.serviciofloracion.actualizarPeriodoFloracion(this.idPeriodo, this.PeriodoForm.value).subscribe(response => {

      if(this.floraciones.length > 0){
        this.floraciones.forEach(floracion => {
          delete floracion.isEdit;
          floracion.idPeriodo = this.idPeriodo;
          this.serviciofloracion.crearFloraciones(floracion).subscribe(response => {
          });
        });
      }

      this.PeriodoForm.markAsPristine()
      this.PeriodoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/floraciones')
    });
  }

  obtenerFloraciones = (id) => {
    let array: any = []
    this.dataSourceAudio.length = 0;
    this.serviciofloracion.getFloraciones(id).subscribe(response => {
      array = response;
      array.forEach(floracion => {
        this.dataSourceAudio.push(floracion)
        this.tableA.renderRows()
      });
    });
  }

  editarFloracion(row) {
    if (row.id === 0) {
    } else {
      if (row.idFloracion) {
        delete row.isEdit
        this.serviciofloracion.actualizarFloraciones(row.idFloracion, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerFloraciones(this.idPeriodo)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarFloracion(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciofloracion.eliminarFloraciones(id).subscribe(() => {

          this.obtenerFloraciones(this.idPeriodo)
        })
      }
    })
  }


  guardarFloracion = () => {
    if (this.FloracionForm.get('nombre').value != null) {
      this.dataSourceAudio.push(this.FloracionForm.value)
      this.floraciones.push(this.FloracionForm.value)
      this.tableA.renderRows()
      this.FloracionForm.reset()
    }
  }



    //GUARDAR IMAGEN EN PROYECTO
    guardarImagenPHP = (imagen: any) => {
      imagen._files.forEach(element => {
        this.ImagenesForm.controls['rutaImagen'].setValue(element);
        const imgData = new FormData();
        imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
        this.serviciodocumento.guardarImg(imgData).subscribe(response => {
        });
      });
  
    }
  
    eliminarImagen = (id, name) =>{
      this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
        if (confirm) {
       this.serviciofloracion.eliminarImagen(id).subscribe(response=>{
         this.serviciodocumento.eliminarImagen(name).subscribe(response =>{
  
         });
        this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
        this.ngOnInit()
       })
        }
    
      })
    }
  


}
