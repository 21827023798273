import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { NosotrosService } from 'app/services/inicio/nosotros.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nosotros-detalle',
  templateUrl: './nosotros-detalle.component.html',
  styleUrls: ['./nosotros-detalle.component.scss']
})
export class NosotrosDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idSeccion: string = this.obtenerId().idSeccion;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_icono: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  NosotrosForm = new FormGroup({

    seccion: new FormControl('', Validators.required),
    contenido: new FormControl('', Validators.required),
    icono: new FormControl(''),
    orden: new FormControl(0),
    estado: new FormControl(1),

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicionosotros: NosotrosService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idSeccion) {
      this.servicionosotros.getDatoNosotros(this.idSeccion).subscribe(response => {
        this.NosotrosForm.patchValue(response[0])
        this.imagen_icono = response[0].icono;

      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.NosotrosForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let datos: any = [];
    let imagen = this.NosotrosForm.get('icono').value;

    if (this.NosotrosForm.get('icono').value) {
      this.NosotrosForm.controls['icono'].setValue(imagen._fileNames)
    }

    this.servicionosotros.getNosotrosDatos().subscribe(response => {
      datos = response;
      if (response) {
        this.NosotrosForm.controls['orden'].setValue(datos.length + 1)
        this.servicionosotros.crearDatoNosotros(this.NosotrosForm.value).subscribe(response => {

          if (this.NosotrosForm.get('icono').value) {
            this.guardarImagenPHP(imagen)
          }

          this.NosotrosForm.markAsPristine()
          this.NosotrosForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/nosotros')
        });
      }
    })



  }

  actualizarDatos = () => {
    let imagen = this.NosotrosForm.get('icono').value;

    if (this.NosotrosForm.get('icono').value) {
      this.NosotrosForm.controls['icono'].setValue(imagen._fileNames)
    }

    this.servicionosotros.actualizarDatoNosotros(this.idSeccion, this.NosotrosForm.value).subscribe(response => {
      if (this.NosotrosForm.get('icono').value) {
        this.guardarImagenPHP(imagen)
      }
      this.NosotrosForm.markAsPristine()
      this.NosotrosForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/nosotros')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    this.NosotrosForm.controls['icono'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.NosotrosForm.get('icono').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });

  }

  eliminarImagen = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicionosotros.actualizarEstadoImagenIcono(1, this.NosotrosForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen_icono).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }


}
