import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ClasificacionService } from 'app/services/galeria/clasificaciones.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-clasificacion-detalle',
  templateUrl: './clasificacion-detalle.component.html',
  styleUrls: ['./clasificacion-detalle.component.scss']
})
export class ClasificacionGaleriaDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idClasificacion: string = this.obtenerId().idClasificacion;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen_icono: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ClasificacionForm = new FormGroup({

    nombre: new FormControl('', Validators.required),
    orden: new FormControl(0)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioclasificacion: ClasificacionService,
    ) { }

  ngOnInit(): void {
    if (this.idClasificacion) {
      this.servicioclasificacion.getClasificacion(this.idClasificacion).subscribe(response => {
        this.ClasificacionForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ClasificacionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let datos: any = [];

    this.servicioclasificacion.getClasificaciones().subscribe(response => {
      datos = response;
      if (response) {
        this.ClasificacionForm.controls['orden'].setValue(datos.length + 1)
        this.servicioclasificacion.crearClasificacion(this.ClasificacionForm.value).subscribe(response =>{
      
          this.ClasificacionForm.markAsPristine()
          this.ClasificacionForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/clasificaciones-galeria')
        });
      }
    })

  }

  actualizarDatos = () => {

    this.servicioclasificacion.actualizarClasificacion(this.idClasificacion, this.ClasificacionForm.value).subscribe(response =>{

      this.ClasificacionForm.markAsPristine()
      this.ClasificacionForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/clasificaciones-galeria')
    });
  }

}
