export const environment = {
  // production: true

  //LOCALHOST
  //RUTA DEL SERVIDOR NODE JS
  // API_URL: 'http://localhost:4000',

  //RUTA DEL SERVIDOR PHP
  // API_SERVER_PHP: 'http://localhost/archivos-oaxaca',

  //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES

  // API_IMAGEN: `http://localhost/archivos-oaxaca/`,
  // API_PDF: `http://localhost/archivos-oaxaca/`,


  //RUTA DEL SERVIDOR NODE JS
  // API_URL: 'http://167.99.41.94:4000',
  // --------------------conexion server------------------ 
  API_URL: 'https://aupadmin.pcoriente.com',



  // //RUTA DEL SERVIDOR PHP
  API_SERVER_PHP: '/archivos-oaxaca',
  //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
  API_PDF: `/archivos-oaxaca/`,
  API_IMAGEN: `/archivos-oaxaca/`


};

// contraseña administrador Oriente65
