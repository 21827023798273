import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClasificacionProductosService } from 'app/services/productos/clasificacion.service';

@Component({
  selector: 'app-clasificacion',
  templateUrl: './clasificacion.component.html',
  styleUrls: ['./clasificacion.component.scss']
})
export class ClasificacionComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'clasificacion', 'estado' , 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private servicioclasificacion: ClasificacionProductosService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicioclasificacion.getClasificacionProductos().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }

  
  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.array);
    this.dataSource.sort = this.sort;
    this.array.reverse()
    this.array.forEach(dato => {
      dato.orden = i;

      if (dato.estado == 'ACTIVO') {
        dato.estado = 1;
      } else {
        dato.estado = 0;
      }

      this.servicioclasificacion.actualizarClasificacionProducto(dato.idClasificacion, dato).subscribe(response => {
      });


      if (dato.estado == 1) {
        dato.estado = 'ACTIVO';
      } else {
        dato.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.array.reverse()
  }

}
