import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GruposPErmisosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getGruposPermisos(){
    return this.http.get(`${this.API_URI}/grupos-permisos`)
  }

  getGrupoPermiso(id:string){
    return this.http.get(`${this.API_URI}/grupos-permisos/${id}`);
  }

  actualizarGrupoPermiso(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/grupos-permisos/${id}`, datos);
  }
  
  crearGrupoPermiso(datos: any) {
    return this.http.post(`${this.API_URI}/grupos-permisos`, datos);
  }

  eliminarGrupoPermiso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/grupos-permisos/${id}`);
  }

  getSecciones(){
    return this.http.get(`${this.API_URI}/grupos-permisos/secciones/usuario`)
  }

  
  actualizarSeccion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/grupos-permisos/secciones/${id}`, datos);
  }
  
  crearSeccion(datos: any) {
    return this.http.post(`${this.API_URI}/grupos-permisos/secciones/usuario`, datos);
  }

  getPermisosUsuario(id:string){
    return this.http.get(`${this.API_URI}/grupos-permisos/secciones/${id}`);
  }

  eliminarPermiso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/grupos-permisos/secciones/${id}`);
  }

  getSeccionesRestantes(id:string){
    return this.http.get(`${this.API_URI}/grupos-permisos/seccionesRestante/${id}`);
  }
}