import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class FloracionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getFloracionImagen(id:string | number){
    return this.http.get(`${this.API_URI}/floraciones/${id}`);
  }
  
  actualizarFloracionImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/floraciones/${id}`, datos);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/floraciones/imagen/${id}`, datos);
  }

  //PERIODOS DE FLORACION

  getPeriodosFloraciones(){
    return this.http.get(`${this.API_URI}/floraciones`)
  }

  getPeriodoFloracion(id:string){
    return this.http.get(`${this.API_URI}/floraciones/periodo/${id}`);
  }

  actualizarPeriodoFloracion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/floraciones/periodo/${id}`, datos);
  }
  
  crearPeriodoFloracion(datos: any) {
    return this.http.post(`${this.API_URI}/floraciones`, datos);
  }

  //FLORACION

  getFloraciones(id:string){
    return this.http.get(`${this.API_URI}/floraciones/floracion/${id}`);
  }

  actualizarFloraciones(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/floraciones/floracion/${id}`, datos);
  }
  
  crearFloraciones(datos: any) {
    return this.http.post(`${this.API_URI}/floraciones/floracion/crear`, datos);
  }

  eliminarFloraciones(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/floraciones/floracion/${id}`);
  }

  //IMAGENES POR FLORACION

  getImagenes(id:string){
    return this.http.get(`${this.API_URI}/floraciones/imagenes/${id}`);
  }

  crearImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/floraciones/imagenes/porfloracion`, datos);
  }

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/floraciones/imagenes/${id}`);
  }

}
