import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ProveedoresService } from 'app/services/proveedores/proveedores.service';
import { DataService } from 'app/services/secciones.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-proveedor-detalle',
  templateUrl: './proveedor-detalle.component.html',
  styleUrls: ['./proveedor-detalle.component.scss']
})
export class ProveedorDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idProveedor: string = this.obtenerId().idProveedor;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ProveedorForm = new FormGroup({

    nombre: new FormControl('', Validators.required)

  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioprovedor: ProveedoresService,
    ) { }

  ngOnInit(): void {
    if (this.idProveedor) {
      this.servicioprovedor.getProveedor(this.idProveedor).subscribe(response => {
        this.ProveedorForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ProveedorForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

        this.servicioprovedor.crearProveedor(this.ProveedorForm.value).subscribe(response =>{
          this.ProveedorForm.markAsPristine()
          this.ProveedorForm.markAllAsTouched()
          this.ProveedorForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/proveedores')
        });

  }

  actualizarDatos = () => {

    this.servicioprovedor.actualizarProveedor(this.idProveedor, this.ProveedorForm.value).subscribe(response =>{

      this.ProveedorForm.markAsPristine()
      this.ProveedorForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/proveedores')
    });
  }
}
