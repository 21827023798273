import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ProveedoresService } from 'app/services/proveedores/proveedores.service';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.scss']
})
export class ProveedoresComponent implements OnInit {
  displayedColumns: string[] = ['idProveedor', 'nombre', 'ver' , 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private servicioproveedor: ProveedoresService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicioproveedor.getProvedores().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }

  eliminarDato = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioproveedor.eliminarProveedor(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
