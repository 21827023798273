import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
// import { AuthService } from './auth.service';
import 'rxjs/add/operator/map';
import { LoginService } from './login.service';

@Injectable()
export class AuthorizationHeaderService {
    constructor(private tokenservice: LoginService ) { }

    public setAuthHeader(getHeader?: HttpHeaders): HttpHeaders {
        let headers: HttpHeaders = getHeader ? getHeader : new HttpHeaders();
        headers.append('authorization', 'PRUEBA');

        this.tokenservice.getToken().subscribe(response => {
            if(response){
                let token: any = response;
                headers.append('authorization', token.token);
                // headers.set('authorization', token.token);
                return headers;
            }
          })
          headers.append('authorization', 'PRUEBA');

          return headers;
        // let userToken = this.auth.getUserData().data.token;
    }

}
