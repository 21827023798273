import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/services/documentos.service';
import { ProductoMarcaService } from 'app/services/productos/marcas.service';
import { DataService } from 'app/services/secciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-marca-detalle',
  templateUrl: './marca-detalle.component.html',
  styleUrls: ['./marca-detalle.component.scss']
})
export class MarcaDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idMarca: string = this.obtenerId().idMarca;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  MarcaForm = new FormGroup({

    marca: new FormControl('', Validators.required),
    logo: new FormControl('', Validators.required),
    orden: new FormControl(1),
    estado: new FormControl(1)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciomarcas: ProductoMarcaService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idMarca) {
      this.serviciomarcas.getMarca(this.idMarca).subscribe(response => {
        this.MarcaForm.patchValue(response[0])
        this.imagen = response[0].logo;

      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.MarcaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let datos: any = []
    let imagen = this.MarcaForm.get('logo').value;

    if (this.MarcaForm.get('logo').value) {
      this.MarcaForm.controls['logo'].setValue(imagen._fileNames)
    }

    this.serviciomarcas.getCMarcas().subscribe(response => {
      datos = response;      
      if (response) {
        this.MarcaForm.controls['orden'].setValue(datos.length + 1)
        this.serviciomarcas.crearMarca(this.MarcaForm.value).subscribe(response => {

          if (this.MarcaForm.get('logo').value) {
            this.guardarImagenPHP(imagen)
          }

          this.MarcaForm.markAsPristine()
          this.MarcaForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/marcas')
        });
      }
    })



  }

  actualizarDatos = () => {
    let imagen = this.MarcaForm.get('logo').value;

    if (this.MarcaForm.get('logo').value) {
      this.MarcaForm.controls['logo'].setValue(imagen._fileNames)
    }

    this.serviciomarcas.actualizarMarca(this.idMarca, this.MarcaForm.value).subscribe(response => {
      if (this.MarcaForm.get('logo').value) {
        this.guardarImagenPHP(imagen)
      }
      this.MarcaForm.markAsPristine()
      this.MarcaForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/marcas')
    });
  }

  guardarImagenPHP = (imagen: any) => {

    this.MarcaForm.controls['logo'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.MarcaForm.get('logo').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });

  }

  eliminarImagen = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciomarcas.actualizarEstadoImagen(this.idMarca, this.MarcaForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }


}
